import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Rodape extends Component {

  constructor(props) {
    super(props);
  }

  data_atual = new Date();

  render() {
    return (
      <footer className="footer-3 footer-topbar light page-section-pt">
        <div className="container">
          <div className="row top">
            <div className="col-lg-3 col-md-12">
              <img
                className="img-fluid"
                id="logo_footer_dark"
                src="images/logo-dark.png"
                alt=""
              />
            </div>

            <div className="col-lg-4 col-md-12">
              <div className="social text-lg-right text-center">
                <ul>
                  <li>
                    <a href="https://www.facebook.com/realveiculosudi/">
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/realveiculosuberlandia/">
                      <i className="fa fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://api.whatsapp.com/send?phone=5534998798068&text=Ol%C3%A1%2C%20vi%20um%20an%C3%BAncio%20em%20seu%20site%20e%20gostaria%20de%20saber%20mais%20sobre%20o%20ve%C3%ADculo">
                      <i className="fa fa-whatsapp"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.google.com/maps/place/Av.+Brasil,+2497+-+Brasil,+Uberl%C3%A2ndia+-+MG,+38400-718/@-18.9010327,-48.270266,17z/data=!3m1!4b1!4m5!3m4!1s0x94a445b9ca602b21:0xd3fff8f1051a7acb!8m2!3d-18.9010378!4d-48.2680773">
                      <i className="fa fa-map-marker"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <hr />
            </div>
          </div>
          <div className="row" style={{display:"flex", justifyContent: "center", textAlign: "center"}}>
            <div className="col-lg-5 col-md-12">
              <div className="about-content">
                <h6 className="text-black">Sobre nós</h6>
                <p className="text-gray">
                  Com uma postura personalizada no mercado premium de veículos, a Real Veículos 
                  traz um novo conceito de trabalho e atendimento, visando oferecer sempre o 
                  melhor aos seus clientes. A empresa – que nasceu através de uma paixão por 
                  automóveis – atua nos seguimentos de veículos zero quilômetro e seminovos 
                  de qualquer marca.
                </p>
              </div>
            </div>
            <div className="col-lg-7 col-md-12">
              <h6 className="text-black">Informações da loja</h6>
              <div className="usefull-link">
                <div className="row">
                  <div className="col-md-4 footer_info">
                    <ul>
                      <li>
                        <a href="https://www.google.com/maps/place/R.+Maj.+Gote,+1645+-+Centro,+Patos+de+Minas+-+MG,+38700-207/@-18.5979413,-46.5169089,17z/data=!3m1!4b1!4m5!3m4!1s0x94ae8ab566991391:0x8e335e573c93fb75!8m2!3d-18.5979413!4d-46.5147202">
                          <i className="fa fa-map-marker"></i>
                          Av. Brasil, 2497 - Brasil, Uberlândia - MG, CEP 38400-712
                        </a>
                      </li>
                      <li>
                        <a href="">
                          <i className="fa fa-phone"></i>
                          (34) 3232-8871
                        </a>
                      </li>
                      <li>
                        <a href="https://api.whatsapp.com/send?phone=5534998798068&text=Ol%C3%A1%2C%20vi%20um%20an%C3%BAncio%20em%20seu%20site%20e%20gostaria%20de%20saber%20mais%20sobre%20o%20ve%C3%ADculo">
                          <i className="fa fa-whatsapp"></i>
                          (34) 9 9879 - 8068
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa fa-envelope-o"></i>
                          contato@realveiculosudi.com.br
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright">
          <div className="container">
            <div className="row" style={{display:"flex", justifyContent:"center"}}>
              <div className="col-lg-6 col-md-12">
                <div className="text-lg-left text-center">
                  <p style={{textAlign:"center"}}>
                    © Copyright 2022 Real Veículos | Todos os direitos reservados.
                    <a href="https://intermedioveiculos.com.br/" target="_blank"
                    > Intermédio Veículos Marketing Digital</a
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

    );
  }
}

function retiraMascara(value, type = "texto") {
  var numero = '';
  if (value !== undefined) {
    numero = value.replace('_', '');
    if (type === "link") {
      numero = numero.replace('(', '').replace(')', '').replace('-', '').replace(' ', '');
    }
  }
  return numero;
}

export default Rodape;