import React, { Component } from 'react';
import Cabecalho from '../componentes/Cabecalho';
import Rodape from '../componentes/Rodape';
import { firestore } from './../firebase-config';
import { Link } from 'react-router-dom';
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';
import MenuItem from "@material-ui/core/MenuItem";
import Slider from "react-slick";
import { id_firebase } from '../content-site';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

function VeiculosRecentes(data) {
    const veiculos = data.veiculos;
    const listVeiculos = veiculos.map((v) =>
        <div className="item" key={v.id}>
            <div className="car-item text-center">
                <div className="car-image">
                    {
                        v.imagens.length > 0 ?
                            v.imagens[0].fileThumb !== undefined ?
                                <img className="img-fluid" src={v.imagens[0].fileThumb} alt=""></img>
                                :
                                <img className="img-fluid" src={v.imagens[0].fileUrl} alt=""></img>
                            : <img className="img-fluid" src="/img/car_default.png" alt='imagem veículo'></img>
                    }
                    <div className="car-overlay-banner">
                        <ul>
                            <li><Link to={"/veiculo/" + v.id}><i className="fa fa-link"></i></Link></li>
                        </ul>
                    </div>
                </div>
                <div className="car-list">
                    <ul className="list-inline">
                        <li><i className="fa fa-registered"></i>{v.veano_fabricacao}/{v.vefipe_ano_modelo}</li>
                        <li><i className="fa fa-cog"></i>{formatCambio(v.vecambio)}</li>
                        <li><i className="fa fa-dashboard"></i>{v.vequilometragem} KM</li>
                    </ul>
                </div>
                <div className="car-content">
                    <Link to={"/veiculo/" + v.id}>{v.vefipe_marca} {v.vefipe_name}</Link>
                    <div className="separator"></div>
                    <div className="price">
                        <span className="new-price">{formatMoney(v.vevalor)}</span>
                    </div>
                </div>
            </div>
        </div>
    );

    return <OwlCarousel className='owl-theme' autoplay={true} loop={true} items={2} margin={30} nav>{listVeiculos}</OwlCarousel>

}




class Estoque extends Component {

    constructor(props) {
        super(props);

        this.state = {
            veiculos: [],
            veiculosComplete: [],
            countVeiculos: 0,
            instagramData: [],
            buscaVeiculo: '',
            tipoVeiculo: 'todos',
            revenda: {},
            bannerData: []
        }
        this.mounted = false;

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {

        window.scrollTo(0, 0);

        this.mounted = true;

        firestore.collection("revenda").doc(id_firebase)
            .get()
            .then(doc => {
                if (doc.exists) {
                    this.setState({
                        revenda: doc.data(),
                    });
                } else {
                    this.setState({
                        revenda: {
                            ativo: 0,
                        }
                    });
                }
            }).catch(function (error) {
            });

        firestore.collection("revenda").doc(id_firebase).collection("veiculos").where("veremovido", "==", 0).where("vevendido", "==", 0).orderBy("updated_at", "desc")
            .onSnapshot(querySnapshot => {
                var items = [];
                querySnapshot.forEach(doc => {
                    var document = doc.data();
                    document.id = doc.id;
                    if (document["veexibir_site"] !== false) {
                        items.push(document);
                    }
                });
                if (this.mounted) {
                    this.setState({
                        veiculos: items,
                        veiculosComplete: items,
                        countVeiculos: items.length
                    });
                }
            });

        firestore.collection("revenda").doc(id_firebase).collection("imagem_destaque").orderBy("created_at", "desc")
            .onSnapshot(querySnapshot => {
                var items = [];
                querySnapshot.forEach(doc => {
                    var document = doc.data();
                    document.id = doc.id;
                    items.push(document);
                });
                if (this.mounted) {
                    this.setState({
                        instagramData: items
                    });
                }
            });

        firestore.collection("revenda").doc(id_firebase).collection("banner_site").orderBy("created_at", "desc")
            .onSnapshot(querySnapshot => {
                var items = [];
                querySnapshot.forEach(doc => {
                    var document = doc.data();
                    document.id = doc.id;
                    if (document.imagem_url !== undefined && document.imagem_url.trim().length > 0) {
                        items.push(document.imagem_url);
                    }
                });
                if (this.mounted) {
                    this.setState({
                        bannerData: items
                    });
                }
            });

    }

    componentWillUnmount() {
        this.mounted = false;
    }

    handleChange(event) {

        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {

        let filtro = this.state.buscaVeiculo.toLowerCase();
        let tipoVeiculo = this.state.tipoVeiculo;

        let veiculosFilter = this.state.veiculosComplete.filter(function (veiculo) {
            let descricaoVeiculo = veiculo.vefipe_marca.toLowerCase() + " " + veiculo.vefipe_name.toLowerCase();
            if (tipoVeiculo === 'novos') {
                return descricaoVeiculo.includes(filtro) && veiculo.vequilometragem <= 100;
            }
            if (tipoVeiculo === 'seminovos') {
                return descricaoVeiculo.includes(filtro) && veiculo.vequilometragem > 100;
            }
            return descricaoVeiculo.includes(filtro);
        });

        this.setState({
            veiculos: veiculosFilter
        });

    }

    handleClick(event) {
        this.setState({
            veiculos: this.state.veiculosComplete,
            buscaVeiculo: '',
            tipoVeiculo: 'todos',
        });
    }

    render() {
        const {
            veiculos,
            instagramData,
            tipoVeiculo,
            bannerData
        } = this.state;

        return (
            <div>
                <Cabecalho revenda={this.state.revenda} />


                <section className="inner-intro bg-1 bg-overlay-black-70">
                    <div className="container">
                        <div className="row text-center intro-title">
                            <div className="col-md-12 text-md-left d-inline-block">
                                <h1 className="text-white">ESTOQUE</h1>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="product-listing page-section-ptb" style={{ paddingTop: "0px" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <ValidatorForm id="contactForm" className="s-form wow zoomInUp" ref="form" onSubmit={this.handleSubmit} >
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <TextValidator
                                                placeholder="Ex.: Fiat Palio"
                                                label="Encontre seu veículo"
                                                onChange={this.handleChange}
                                                name="buscaVeiculo"
                                                value={this.state.buscaVeiculo}
                                                className="form-ui-bootstrap" />
                                        </div>

                                        <div className="col-lg-3" style={{ paddingTop: "44px" }}>
                                            <SelectValidator
                                                value={tipoVeiculo}
                                                name="tipoVeiculo"
                                                className="form-ui-bootstrap select-busca-veiculos"
                                                onChange={this.handleChange}>
                                                <MenuItem value="todos">Todos</MenuItem>
                                                <MenuItem value="novos">0 Km</MenuItem>
                                                <MenuItem value="seminovos">Seminovos</MenuItem>
                                            </SelectValidator>
                                        </div>

                                        <div className="col-lg-3 text-center" style={{ paddingTop: "30px" }}>
                                            <button type="submit" className="btn m-btn">BUSCAR VEÍCULOS<span className="fa fa-angle-right"></span></button><br />
                                            <p className="linkLimparBusca text-center" onClick={this.handleClick}>LIMPAR BUSCA</p>
                                        </div>
                                    </div>

                                    {/*
                                    <div className="row sorting-options">
                                        <div className="col-lg-5">
                                            <div className="change-view-button">
                                                <a className="active" href="#"> <i className="fa fa-th"></i> </a>
                                                <a href="listing-02.html"> <i className="fa fa-list-ul"></i> </a>
                                            </div>
                                        </div> 
                                        
                                    </div>
*/}
                                </ValidatorForm>

                                <div className="row">
                                    {Object.keys(veiculos)
                                        .map(key => {
                                            return (
                                                <div className="col-lg-2" data-wow-delay="0.5s" key={veiculos[key].id}>
                                                    <div className="car-item gray-bg text-center">
                                                        <div className="car-image">
                                                            {
                                                                veiculos[key].imagens.length > 0 ?
                                                                    veiculos[key].imagens[0].fileThumb !== undefined ?
                                                                        <img className="img-fluid" src={veiculos[key].imagens[0].fileThumb} alt=""></img>
                                                                        :
                                                                        <img className="img-fluid" src={veiculos[key].imagens[0].fileUrl} alt=""></img>
                                                                    : <img className="img-fluid" src="/images/car_default.png" alt='imagem veículo'></img>
                                                            }

                                                            <div className="car-overlay-banner">
                                                                <ul>
                                                                    <li><Link to={"/veiculo/" + veiculos[key].id}><i className="fa fa-link"></i></Link></li>
                                                                    <li><Link to={"/veiculo/" + veiculos[key].id}><i className="fa fa-dashboard"></i></Link></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="car-list">
                                                            <ul className="list-inline">
                                                                <li><i className="fa fa-registered"></i>{this.state.veiculos[key].veano_fabricacao}/{this.state.veiculos[key].vefipe_ano_modelo}</li>
                                                                <li><i className="fa fa-cog"></i>{formatCambio(veiculos[key].vecambio)}</li>
                                                                <li><i className="fa fa-dashboard"></i>{veiculos[key].vequilometragem} KM</li>
                                                            </ul>
                                                        </div>
                                                        <div className="car-content">
                                                            <div className="star" style={{color:"#FFF"}}>
                                                                <i className="fa fa-star orange-color"></i>
                                                                <i className="fa fa-star orange-color"></i>
                                                                <i className="fa fa-star orange-color"></i>
                                                                <i className="fa fa-star orange-color"></i>
                                                                <i className="fa fa-star orange-color"></i>
                                                            </div>
                                                            <span style={{color:"#FFF"}}>{veiculos[key].veano_fabricacao}/{veiculos[key].vefipe_ano_modelo}</span>
                                                            <Link to={"/veiculo/" + veiculos[key].id}>{veiculos[key].vefipe_marca} {veiculos[key].vefipe_name}</Link>
                                                            <div className="separator"></div>
                                                            <div className="price">
                                                                <span className="new-price">{formatMoney(veiculos[key].vevalor)}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Rodape revenda={this.state.revenda} />
            </div>
        )
    }
}

function formatMoney(value) {

    var places = 2;
    var symbol = "R$ ";
    var thousand = ".";
    var decimal = ",";

    places = !isNaN(places = Math.abs(places)) ? places : 2;
    symbol = symbol !== undefined ? symbol : "$";
    thousand = thousand || ",";
    decimal = decimal || ".";
    var number = value,
        negative = number < 0 ? "-" : "",
        i = parseInt(number = Math.abs(+number || 0).toFixed(places), 10) + "",
        j = (j = i.length) > 3 ? j % 3 : 0;
    return symbol + negative + (j ? i.substr(0, j) + thousand : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousand) + (places ? decimal + Math.abs(number - i).toFixed(places).slice(2) : "");
};

function formatCambio(value) {
    if (value === 'A') {
        return "Automático";
    } else {
        return "Manual";
    }
}

export default Estoque;